<template>
  <mp-popover ref="switch-account-dropdown" @open="getCurrentWorkloadAgent">
    <mp-popover-trigger>
      <mp-button variant="unstyled" height="auto" margin-left="5">
        <mp-flex
          align-items="center"
          border-radius="md"
          transition="all 0.2s ease-in-out"
          px="2"
          py="1"
          :_hover="{ bg: 'background', cursor: 'pointer' }"
        >
          <mp-avatar
            :name="$auth.user.full_name || ''"
            :src="$auth.user.avatar ? $auth.user.avatar.large.url : null"
            size="md"
            margin-right="2"
            :show-border="false"
          />
          <mp-flex
            flex-direction="column"
            :display="{
              xl: 'block',
              lg: 'block',
              md: 'block',
              sm: 'none',
              xs: 'none',
            }"
          >
            <mp-text font-weight="semibold" line-height="md">
              {{ $auth.user.full_name || '' }}
            </mp-text>
            <mp-text font-size="sm" color="gray.600">
              {{ $auth.user.email || '' }}
            </mp-text>
          </mp-flex>
        </mp-flex>
      </mp-button>
    </mp-popover-trigger>
    <mp-popover-content
      z-index="15"
      max-width="65"
      border-radius="md"
      box-shadow="lg"
      border="1px solid"
      border-color="gray.400"
      background-color="white"
    >
      <mp-box
        background-color="background"
        border-bottom="1px"
        border-color="gray.100"
        border-top-radius="md"
        p="3"
      >
        <mp-flex>
          <mp-avatar
            :name="$auth.user.full_name || ''"
            :src="$auth.user.avatar ? $auth.user.avatar.large.url : null"
            size="md"
            margin-right="2"
          />
          <mp-box>
            <mp-text font-weight="semibold" line-height="md">{{
              $auth.user.full_name || ''
            }}</mp-text>
            <mp-text color="gray.600" font-size="sm">{{
              $auth.user.email || ''
            }}</mp-text>
            <mp-flex mt="1">
              <mp-badge
                v-if="$auth.user.role"
                variant="subtle"
                :variant-color="getRoleVariantColor($auth.user.role)"
                class="text-capitalize"
                font-size="sm"
                mr="2"
                >{{ $auth.user.role.replace('_', ' ') || '' }}</mp-badge
              >
              <mp-badge
                v-if="credentials.length"
                variant="subtle"
                :variant-color="getAppTypeVariantColor($auth.user.role)"
                class="text-capitalize"
                font-size="sm"
                >{{
                  credentials.length ? credentials[0].app_type : ''
                }}</mp-badge
              >
            </mp-flex>
          </mp-box>
        </mp-flex>
      </mp-box>
      <mp-box v-if="!isAgent()" as="div" px="3" py="2">
        <mp-box as="div">
          <mp-flex justify-content="space-between" w="100%">
            <mp-text font-size="sm" color="gray.600">WhatsApp Business</mp-text>
            <mp-text font-size="sm" font-weight="semibold">{{
              credentials.length
                ? credentials[0].settings.waba_name
                : 'No Integration'
            }}</mp-text>
          </mp-flex>
        </mp-box>
      </mp-box>
      <mp-box v-if="isAgent()" as="div" px="3" py="2">
        <mp-box as="div">
          <mp-flex
            justify-content="space-between"
            w="100%"
            class="centered-item"
          >
            <mp-text> Ongoing conversations </mp-text>
            <mp-text
              v-if="agentWorkload?.limit_ongoing_chat?.enabled"
              font-size="sm"
              :color="
                agentWorkload.assigned_count ==
                agentWorkload.limit_ongoing_chat.max
                  ? 'red'
                  : 'gray.600'
              "
            >
              {{
                `${agentWorkload.assigned_count}/${agentWorkload.limit_ongoing_chat.max}`
              }}
            </mp-text>
            <mp-text
              v-if="!agentWorkload?.limit_ongoing_chat?.enabled"
              font-size="sm"
              color="gray.600"
            >
              {{ agentWorkload.assigned_count }}
            </mp-text>
          </mp-flex>
        </mp-box>
      </mp-box>
      <mp-divider border-color="gray.100" m="0" />
      <mp-pseudo-box
        v-if="accountSettings"
        :_hover="{
          backgroundColor: 'ice.50',
          color: 'blue.400',
          cursor: 'pointer',
        }"
        as="div"
        px="3"
        py="2"
        @click="goAccountSettingsURL"
      >
        <mp-text> Account settings </mp-text>
      </mp-pseudo-box>
      <mp-divider border-color="gray.100" m="0" />
      <mp-pseudo-box
        v-if="isShowSupportCenter"
        :_hover="{
          backgroundColor: 'ice.50',
          color: 'blue.400',
          cursor: 'pointer',
        }"
        as="div"
        px="3"
        py="2"
        @click="openSupportCenter"
      >
        <MpFlex justify-content="space-between">
          <mp-text> Support center </mp-text>
          <MpIcon name="newtab" size="sm" />
        </MpFlex>
      </mp-pseudo-box>
      <mp-pseudo-box
        :_hover="{
          backgroundColor: 'ice.50',
          color: 'blue.400',
          cursor: 'pointer',
          'border-radius': '0 0 4px 4px',
        }"
        as="div"
        px="3"
        py="2"
        @click="signOutOnClick"
      >
        <mp-text> Sign out </mp-text>
      </mp-pseudo-box>
    </mp-popover-content>
  </mp-popover>
</template>

<script>
import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { deleteToken } from 'firebase/messaging'
import moengage from '@moengage/web-sdk'
import { messaging } from '@/plugins/firebase'
import { agentWorkload } from '@/common/services'

export default {
  name: 'LayoutsMainSwitchAccount',

  data() {
    return {
      agentWorkload: {},
    }
  },

  computed: {
    ...mapState('template', ['credentials']),
    ...mapState('fcm', ['fcmToken']),
    ...mapState('layouts', [
      'incoming_notification',
      'notification_sound',
      'browser_type',
      'userStatus',
      'userBanks',
      'downtime',
      'chatbotMode',
      'appConfig',
    ]),
    ...mapFields('layouts', ['userStatus.is_online']),
    is_online: {
      get() {
        return this.userStatus.is_online
      },
      set() {
        return this.userStatus.is_online
      },
    },
    userSettings() {
      return this.credentials.length ? this.credentials[0] : {}
    },
    isUsingOngoingChat() {
      return !!this.userSettings?.settings?.limit_ongoing_chat
    },
    isShowSupportCenter() {
      return this.appConfig?.is_support_center_enabled && this.isAdmin()
    },
    accountSettings() {
      return (
        this.appConfig?.sso?.status || (!this.isSupervisor() && !this.isAgent())
      )
    },
  },

  created() {
    this.chatbotLogoutTrigger()
  },

  methods: {
    async getCurrentWorkloadAgent() {
      if (!this.isAgent()) return
      try {
        const { data } = await agentWorkload.currentUserWorkload()
        this.agentWorkload = data.data
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
    },

    getRoleVariantColor(role) {
      switch (role) {
        case 'super_admin':
          return 'grey'
        case 'admin':
          return 'blue'
        case 'supervisor':
          return 'green'
        case 'agent':
          return 'red'
      }
    },

    getAppTypeVariantColor(role) {
      switch (role) {
        case 'full':
          return 'grey'
        case 'outbound':
          return 'green'
        case 'inbox':
          return 'blue'
      }
    },

    signOutOnClick() {
      if (!this.chatbotMode.isEditing) {
        this.doSignOut()
        this.$store.commit('layouts/UPDATE_IS_EDITING_ON_CHATBOT', false)
        this.$store.commit('layouts/UPDATE_POP_UP_STATUS', false)
      } else {
        this.$store.commit('layouts/UPDATE_POP_UP_STATUS', true)
        this.$store.commit('layouts/UPDATE_POP_UP_TRIGGER', 'logout')
      }

      /**
       * @desc it used for reset banner height becomes 0px
       */
      this.$store.commit('layouts/SET_HEIGHT_BANNER_MAINTENANCE', 0)
      this.$store.commit('layouts/SET_HEIGHT_BANNER_USER_STATUS', 0)
      this.$store.commit('layouts/SET_HEIGHT_BANNER_CREDIT_BALANCE', 0)
    },

    async doSignOut() {
      const isUsingSSO = this.appConfig?.sso?.status
      if ((this.isAgent() || this.isSupervisor()) && this.is_online)
        this.$emit('onLoggedOut')
      if (
        this.browser_type !== 'Safari' &&
        localStorage.getItem('subscribe_token')
      ) {
        await this.removeCurrentSubsToken()
        // this.$fire.messaging.deleteToken(localStorage.getItem('subscribe_token'))
        await deleteToken(
          messaging,
          localStorage.getItem('subscribe_token')
        ).catch(() => {})
      }
      await this.unregistWorker()
      const data = {
        token: this.$auth.getToken('hub').split(' ')[1],
      }
      await moengage.destroy_session()
      await this.$auth.logout(data)
      if (isUsingSSO) {
        this.$store.commit('conversation/UPDATE_QUERY_ROOM', '')
        this.$store.commit('layouts/DELETE_ALL_NOTIFICATIONS')
        window.location.replace(
          `${process.env.SSO_ACCOUNT_URL}/sign_out?client_id=${process.env.SSO_UNIFIED_CLIENT_ID}`
        )
      } else {
        this.$router.push('/login')
        this.$store.commit('conversation/UPDATE_QUERY_ROOM', '')
        this.$store.commit('layouts/DELETE_ALL_NOTIFICATIONS')
      }
    },

    async removeCurrentSubsToken() {
      await this.$store
        .dispatch('fcm/removePreviousFcmToken', { token: this.fcmToken })
        .then((message) => {
          localStorage.removeItem('subscribe_token')
        })
        .catch((err) => {
          throw err
        })
      localStorage.removeItem('subscribe_token')
    },

    async chatbotLogoutTrigger() {
      await this.$nuxt.$off('popUpTriggerLogout')
      this.$nuxt.$on('popUpTriggerLogout', () => {
        this.$emit('doLogout')
      })
    },

    goAccountSettingsURL() {
      const isUsingSSO = this.appConfig?.sso?.status
      if (isUsingSSO) {
        window.open(process.env.SSO_URL + `/manage/profiles`, '_blank').focus()
      } else {
        this.$router.push(
          `/settings/accounts_management/users/${this.$auth.user.id}`
        )
      }
    },

    openSupportCenter() {
      const isStaging = process.env.HUB_ENVIRONMENT === 'staging'
      const baseUrl = isStaging
        ? 'https://launchpad.mekari.io'
        : 'https://launchpad.mekari.com'
      window.open(`${baseUrl}/support-center/qontak/activities`, '_blank')
    },

    async unregistWorker() {
      /**
       * @TODO this causes sw deleted on page reload/refresh
       */
      if (window.navigator && navigator.serviceWorker) {
        await navigator.serviceWorker
          .getRegistrations()
          .then((registrations) => {
            for (const registration of registrations) {
              registration.unregister()
            }
          })
          .catch(() => {})
      }
    },
  },
}
</script>
